<template>
  <component is="b-card">
    <div>
      <b-card no-body class="mb-0">
        <b-row>
          <!-- Field: Client -->
          <b-col>
            <b-form-group :label="$t('Product name')" label-for="product_names">
              <multiselect
                v-model="productNameValue"
                multiple
                label="name"
                track-by="id"
                :close-on-select="false"
                :show-labels="false"
                :clear-on-select="false"
                :options="product_names"
              ></multiselect>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('Categorie')" label-for="categorie">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categories"
                v-model="categorieValue"
                label="name"
                input-id="categorie"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('Brand')" label-for="brand">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="brands"
                v-model="brandValue"
                label="name"
                input-id="brand"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('Tranche')" label-for="tranches">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tranches"
                v-model="trancheValue"
                label="name"
                input-id="tranches"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-table
          ref="refDesignationListTable"
          class="position-relative"
          :items="fetchDesignation"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('No matching records found')"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(price)="data">
            <b-input-group append="DH">
              <b-form-input
                placeholder="Price"
                type="number"
                v-on:keyup.enter="saveSubProfilePrice(data)"
                v-model="prices[data.index]"
                :disabled="!isEdit[data.index]"
              />
            </b-input-group>
          </template>
          <template #cell(min_price)="data">
            <b-input-group append="DH">
              <b-form-input
                placeholder="Min price"
                type="number"
                v-on:keyup.enter="saveSubProfilePrice(data)"
                v-model="min_prices[data.index]"
                :disabled="!isEdit[data.index]"
              />
            </b-input-group>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
              v-if="!isEdit[data.index]"
              @click="editSubProfilePrice(data)"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
            >
              <feather-icon icon="EditIcon" size="16" />
            </b-button>

            <b-button
              v-else
              @click="saveSubProfilePrice(data)"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
            >
              <feather-icon icon="CheckIcon" size="16" />
            </b-button>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{
                $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
              }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalDesignation"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </component>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormTextarea,
  BInputGroup,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import useDesignationPriceList from "./useDesignationPriceList";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Multiselect from "vue-multiselect";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    Multiselect,
  },
  directives: {
    Ripple,
  },
  async mounted() {
    try {
      const res = await Promise.all([
        instance.get("/parameters/categorie-designation/"),
        instance.get("/parameters/brands/all/"),
        instance.get("/parameters/tranches/all/"),
        instance.get("/parameters/products-names/all/"),
      ]);
      this.categories = res[0].data;
      this.brands = res[1].data;
      this.tranches = res[2].data;
      this.product_names = res[3].data;
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: err.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  },
  methods: {
    fetchCategoryOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/clients/", {
            params: { search },
          });
          this.clientsRes = res.data.results;
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
    editSubProfilePrice(data) {
      this.isEdit[data.index] = true;
      this.refetchData();
    },
    async saveSubProfilePrice(data) {
      var sub_profile = this.$router.currentRoute.params.id;
      var product_combination = data.item.id;
      var price = this.prices[data.index];
      var min_price = this.min_prices[data.index];

      if (!price || !min_price) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("You must enter the price."),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }

      if (parseInt(min_price) >= parseInt(price)) {
        this.prices = this.prices.map((e, index) =>
          index === data.index ? "" : e
        );

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("Min price must be less than the price."),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }

      try {
        await instance.post(
          `/parameters/product-combinations/${product_combination}`,
          {
            sub_profile,
            price: parseFloat(price).toFixed(2),
            min_price: parseFloat(min_price).toFixed(2),
          }
        );

        this.isEdit[data.index] = false;
        this.refetchData();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text:
              err.message ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
  },

  data() {
    return {
      clientsRes: [],
      timeoutId: null,
      categories: [],
      brands: [],
      product_names: [],
      tranches: [],
    };
  },

  setup() {
    const {
      fetchDesignation,
      tableColumns,
      perPage,
      currentPage,
      totalDesignation,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDesignationListTable,
      refetchData,
      categorieValue,
      brandValue,
      trancheValue,
      productNameValue,
      prices,
      min_prices,
    } = useDesignationPriceList();

    const isEdit = ref([...Array(perPage.value).keys()].map((e) => false));

    return {
      fetchDesignation,
      tableColumns,
      perPage,
      currentPage,
      totalDesignation,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDesignationListTable,
      refetchData,
      // Filter
      avatarText,
      isEdit,
      categorieValue,
      brandValue,
      trancheValue,
      productNameValue,
      prices,
      min_prices,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
