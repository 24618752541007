import { ref, watch, computed } from "@vue/composition-api";

// Notification
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { useToast } from "vue-toastification/composition";
import { useRouter } from "@/@core/utils/utils";

export default function useDesignationList() {
  // Use toast
  const { t } = useI18nUtils();
  const toast = useToast();
  const router = useRouter();

  const refDesignationListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "product_shortcut", label: t("Product") },
    { key: "min_price", label: t("Min price") },
    { key: "price", label: t("P.VT") },
    { key: "actions", label: t("Actions") },
  ];
  const perPage = ref(10);
  const totalDesignation = ref(0);
  const currentPage = ref(1);
  const categorieValue = ref("");
  const brandValue = ref("");
  const trancheValue = ref("");
  const productNameValue = ref([]);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const prices = ref([]);
  const min_prices = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = refDesignationListTable.value
      ? refDesignationListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDesignation.value,
    };
  });

  const refetchData = () => {
    refDesignationListTable.value.refresh();
  };

  watch(
    [
      currentPage,

      perPage,
      categorieValue,
      brandValue,
      trancheValue,
      productNameValue,
      searchQuery,
    ],
    () => {
      refetchData();
    }
  );

  const fetchDesignation = (ctx, callback) => {
    prices.value = [];
    min_prices.value = [];
    instance
      .get("/parameters/product-combinations/", {
        params: {
          sub_profile: router.route.value.params.id,
          page: currentPage.value,
          category: categorieValue.value?.id,
          brand: brandValue.value?.id,
          tranche: trancheValue.value?.id,
          product_names: productNameValue.value.map((e) => e.id).join(","),
        },
      })
      .then((response) => {
        const { count, results } = response.data;
        for (var index in results) {
          if (results[index].designation_price?.price) {
            prices.value.push(results[index].designation_price.price);
          } else {
            prices.value.push("");
          }
        }
        for (var index in results) {
          if (results[index].designation_price?.min_price) {
            min_prices.value.push(results[index].designation_price.min_price);
          } else {
            min_prices.value.push("");
          }
        }

        totalDesignation.value = count;
        callback(results);
      })
      .catch((err) => {
      });
  };

  return {
    fetchDesignation,
    tableColumns,
    perPage,
    currentPage,
    totalDesignation,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDesignationListTable,
    refetchData,
    t,
    categorieValue,
    brandValue,
    trancheValue,
    productNameValue,
    prices,
    min_prices,
  };
}
